import { Component, OnInit } from '@angular/core';
import { GarantieFormWrapperComponent } from '../../components/garantie-form-wrapper/garantie-form-wrapper.component';
import { PageTitleComponent } from '@base/components/page-title/page-title.component';
import { BikeCalculatorComponent } from '../../../../../../../servo-bike/src/app/features/insurance/components/bike-calculator/bike-calculator.component';
import { BikeFormWrapperComponent } from '../../../../../../../servo-bike/src/app/features/insurance/components/bike-form-wrapper/bike-form-wrapper.component';
import { GarantieCalculatorComponent } from '../../components/garantie-calculator/garantie-calculator.component';

@Component({
  selector: 'app-garantie-page',
  standalone: true,
  imports: [
    PageTitleComponent,
    GarantieFormWrapperComponent,
    BikeCalculatorComponent,
    BikeFormWrapperComponent,
    GarantieCalculatorComponent
  ],
  templateUrl: './garantie-page.component.html',
  styleUrl: './garantie-page.component.scss'
})
export class GarantiePageComponent implements OnInit {

  submitted = false;

  premium = 0;
  isPremiumSchutz = false;

  servoAdvertiserId: string | null = null;

  showRechner = false;
  noPermission = false;

  ngOnInit() {
    const url = new URL(window.location.href);

    if (url.href.includes('public')) {
      this.handleCustomerViaReferrer(url);
    } else if (url.href.includes('stella')) {
      this.handleCustomerViaApp(url);
    }  else {
      this.handleCustomerViaMakler(url);
    }
  }

  private handleCustomerViaApp(url: URL): void {
    if (!url.searchParams.has('advertiserId')) {
      this.noPermission = true;
      return;
    }

    this.servoAdvertiserId = this.getAdvertiserId(url);
    this.showRechner = true;
  }


  private handleCustomerViaReferrer(url: URL): void {
    if (!this.hasUrlAdvertiserId(url)) {
      this.noPermission = true;
      return;
    }

    this.servoAdvertiserId = this.getAdvertiserId(url);
    this.showRechner = true;
  }

  private handleCustomerViaMakler(url: URL): void {
    this.showRechner = false;

    if (!this.hasUrlAdvertiserId(url) || !url.searchParams.has('isPremiumSchutz')) {
      this.noPermission = true;
      return;
    }

    this.servoAdvertiserId = this.getAdvertiserId(url);

    this.isPremiumSchutz = url.searchParams.get('isPremiumSchutz') === 'true';
    this.premium = Number(url.searchParams.get('premium'));
    this.noPermission = false;
  }

  onRequestOffer(event: { isPremiumSchutz: boolean, premium: number }): void {
    this.premium = event.premium;
    this.isPremiumSchutz = event.isPremiumSchutz;
    this.showRechner = false;
  }

  // there are still some qr-codes which have an old maklerId / referrerId instead of the new advertiserId
  hasUrlAdvertiserId(url: URL): boolean {
    return url.searchParams.has('maklerId') || url.searchParams.has('advertiserId') || url.searchParams.has('referrerId');
  }

  getAdvertiserId(url: URL): string | null {
    if (url.searchParams.has('advertiserId')) {
      return url.searchParams.get('advertiserId')!;
    }

    if (url.searchParams.has('maklerId')) {
      return url.searchParams.get('maklerId')!;
    }

    if (url.searchParams.has('referrerId')) {
      return url.searchParams.get('referrerId')!;
    }

    return null;
  }
}
