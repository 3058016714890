import { Routes } from '@angular/router';
import { GarantiePageComponent } from './features/insurance/pages/garantie-page/garantie-page.component';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () => GarantiePageComponent
  },
  {
    path: 'public',
    loadComponent: () => GarantiePageComponent
  },
  {
    path: 'stella',
    loadComponent: () => GarantiePageComponent
  }
];
